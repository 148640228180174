<template>
  <div  class="ml-5 mr-5">
    <div class="text-right">
       <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
      </div>

<div class="text-left">
        <v-row>
         <v-col cols="3">
      <v-text-field
        v-model="query"
        label="Search By Company Name"
        required
        v-on:keyup.enter="searchCompany"
      ></v-text-field>
          </v-col>

        <v-col cols="1" class="my-4">
        <v-btn small color="primary" @click="searchCompany()">
              Search
        </v-btn>
        </v-col>
        <v-col cols="1" class="my-4">
        <v-btn small @click="getCompany(1,'','reset')">
              Reset
        </v-btn>
         </v-col>
         <v-col cols="4" style="">
            <v-checkbox
              v-model="show_zero_product_count"
              label="Show companies without products"
              color="primary"
              value="yes"
              hide-details
              v-on:click="showproductCount($event)"
            ></v-checkbox>
          </v-col>
          <v-col cols="3" class="my-4 text-right">
            <v-btn small class="mx-4" color="primary" dark 
            @click.prevent="$router.push('/merge-company');"
            v-show="checkIfOperationExistForModule('edit')">
              Merge Companies
            </v-btn>
          </v-col>
          
       </v-row>
       <v-row no-gutters>
        <v-col cols="3"></v-col>
        <v-col cols="3"></v-col>
        <v-col cols="3"></v-col>
         <v-col cols="3" class="my-4 text-right">
           <template>
        <v-dialog v-model="dialog" persistent
      transition="dialog-bottom-transition"
      max-width="900">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small class="mx-2" color="primary" dark v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('add')">
              Add
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add Company</span>
            </v-card-title>
            <v-form
              ref="form"
              @submit="addCompanyData"
              method="post"
              lazy-validation
              v-model="valid"
              enctype= multipart/form-data
            >
              <v-container class="px-50" fluid>
                <div>
                  <v-text-field
                    v-model="addCompany.companyName"
                    label="Company Name"
                    required
                    :rules="nameRules"
                    ref="inputField"
                    @click="showList = true"
                    @keyup="handleBackspace"
                  >
                    <template v-slot:append-outer>
                      <v-progress-circular
                        indeterminate
                        v-if="comp_load"
                        color="primary"
                      ></v-progress-circular>
                    </template>
                </v-text-field>
                  <ul id="comp_list">
                    <li v-if="showList" v-for="company in companies" :key="company.companyID">{{ company.companyName }}</li>
                  </ul>
                </div>
                <div style="color: red">
                  {{ message1 }}
                </div>
              <v-autocomplete
                v-model="addCompany.parentCompany"
                :items="add_parent_items"
                :loading="isLoading"
                item-text="text"
                item-value="value"
                :search-input.sync="search_parent_company"
                label="Parent Company"
                placeholder="Enter minimum 2 characters"
              ></v-autocomplete>
                <v-row>
                          <v-col cols="3">
                            <v-checkbox
                              ref="n"
                              :label="`Approved`"
                              v-model="addCompany.approved"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="3">
                            <v-checkbox
                          ref="n"
                          :label="`Worksite/Voluntary`"
                          v-model="addCompany.worksite"
                        ></v-checkbox>
                          </v-col>
                          <v-col cols="3">
                            <v-checkbox
                          ref="n"
                          :label="`Credit Union`"
                          v-model="addCompany.credit"
                        ></v-checkbox>
                          </v-col>
                          <v-col cols="3">
                            <v-checkbox
                          ref="n"
                          :label="`Private Label`"
                          v-model="addCompany.privateLabel"
                        ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="3">
                            <v-checkbox
                          ref="n"
                          :label="`Insurance Exchange`"
                          v-model="addCompany.insurance"
                        ></v-checkbox>
                          </v-col>
                          <v-col cols="3">
                            <v-checkbox
                          ref="n"
                          :label="`Military`"
                          v-model="addCompany.military"
                        ></v-checkbox>
                          </v-col>
                          <v-col cols="3">
                            <v-checkbox
                          ref="n"
                          :label="`Retail Marketer`"
                          v-model="addCompany.retail"
                        ></v-checkbox>
                          </v-col>
                        </v-row>
                      <v-row>
                        <v-col cols="12">
                              <v-file-input
                                show-size
                                label="Upload Company Logo"
                                accept="image/*"
                                v-model="addCompany.image"
                                prepend-icon="mdi-camera"
                              ></v-file-input>
                              <h6>
                                [Only .jpg and .png files less than 2MB in size can be
                                uploaded]
                              </h6>
                        </v-col>
                      </v-row>
                    <v-row>
                      <v-col cols="12">
                            <v-select
                              v-model="addCompany.state"
                              :items="states"
                              :menu-props="{ maxHeight: '400' }"
                              label="Associated States/Provinces:"
                              multiple
                            ></v-select>
                      </v-col>
                    </v-row>

                <div v-if="sectorData.length > 0">
                  <ul>
                    <li v-for="(c, i) in sectorData" v-bind:key="i">
                      <span v-for="(el, index) in c" v-bind:key="index">
                        <strong v-if="index == 0">{{ el.name }}</strong>
                        <strong v-else>/ {{ el.name }}</strong>
                      </span>
                      <span
                      style="position: static;cursor: pointer;"
                        v-ripple="{ class: `primary--text` }"
                        @click.prevent="traverseSectorCombinations(i, true)"
                      >
                        Up</span
                      >
                      <span
                      style="position: static;cursor: pointer;"
                        v-ripple="{ class: `primary--text` }"
                        @click.prevent="traverseSectorCombinations(i, false)"
                      >
                        Down</span
                      >

                      <span
                      style="position: static;cursor: pointer;"
                        v-ripple="{ class: `primary--text` }"
                        @click.prevent="removeSectorCombination(i)"
                      >
                        Remove</span
                      >
                    </li>
                  </ul>
                </div>

                <v-select
                  v-model="selected_sector"
                  v-show="showAddSectorName"
                  color="purple darken-2"
                  label="Sector"
                  :items="sectors"
                  outlined
                  dense
                  @change="findCateg(selected_sector)"
                ></v-select>
                <v-select
                  v-if="selected_sector"
                  v-model="selected_category"
                  :items="secCategory"
                  v-show="showAddSectorName"
                  color="purple darken-2"
                  label="Category"
                  outlined
                  dense
                  @change="findSubCateg(selected_category)"
                ></v-select>
                <v-select
                  v-if="selected_category"
                  v-model="selected_subcategory"
                  :items="secSubCategory"
                  v-show="showAddSectorName"
                  color="purple darken-2"
                  label="Sub Category"
                  outlined
                  dense
                ></v-select>
                <v-btn
                  v-if="selected_sector"
                  @click="showSectorData()"
                  color="blue darken-1"
                  >Add Sector</v-btn
                >
              </v-container>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" type="submit" @click="validate" >
                  Submit
                </v-btn>
                <v-btn class="close_button mx-2" text  @click="reset">
                  Close
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>

      <v-dialog max-width="600" v-model="dialog2">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small color="error" v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('delete')">Delete</v-btn>
        </template>
        <div v-if="deleteItems.length > 0">
          <template>
            <v-card>
              <v-card-text>
                <div class="text-h3 pa-12">
                  Are you sure you want to delete all the selected Record(s)
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-form ref="form" method="post">
                  <v-btn
                    text
                    class="confirm_button mx-2"
                    v-on:click="dialog2 = false"
                    @click="deleteCompany"
                    >Yes</v-btn
                  >
                </v-form>
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>

        <div v-if="deleteItems.length == 0">
          <template>
            <v-card>
              <v-card-text class="center">
                <div class="text-h3 pa-12">Please Select Record(s) to delete</div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
      </v-dialog>
         </v-col>
         </v-row>
</div>
     <!-- v-data-table  -->
    <v-row>
      <v-col cols="12">
          <div class="tableWrapper">
            <div id="select-all-checkbox">
              <v-checkbox
                ref="n"
                color="default"
                v-model="allSelected"
                @click="selectAll"
                v-show="checkIfOperationExistForModule('delete')"
              ></v-checkbox>
            </div>
            <v-data-table
              :headers="headers"

              item-key="product_id"
              :page.sync="page"
              :items="company_data"
              :options.sync="options"
              :server-items-length="total"
              :loading="isLoading"
              class="elevation-1"
              hide-default-footer
              @page-count="pageCount = $event"
            >
              <template v-slot:item.selectCheckbox="{ item }">

                <v-checkbox
                  ref="n"
                   v-if="
                    (item.product_countPri == 0 &&
                    item.product_countSec == 0 && item.temp_count == 0)
                  "
                  :key="item.company_id"
                  v-model="deleteItems"
                    :value="item.company_id"
                    v-show="checkIfOperationExistForModule('delete')"
                     @click="addToDelete($event, item.company_id)"
                ></v-checkbox>
              </template>
                     <template v-slot:item.action3="{ item }">
                          <div v-if="item.isWorksiteVoluntary === 1">
              Yes
            </div>
            <div v-else>

              <!-- <input
                v-model="item.isWorksiteVoluntary"
                type="checkbox"
                value="false"
                disabled="disabled"
              /> -->
            </div>
              </template>
                <template v-slot:item.action2="{ item }">
                          <div v-if="item.img !== null">
              Yes
            </div>
            <div v-if="item.img === null">

            </div>
              </template>


               <template v-slot:item.actionC="{ item }">
            <div> {{ item.product_countPri }} ({{ item.product_countSec }}) [{{
                item.temp_count
              }}]
            </div>
              </template>


              <template v-slot:item.actions="{ item }">
                 <v-icon
                      medium
                      color="grey"
                       @click="
                        addToUpdate(
                          $event,
                          item.company_id,
                          item.img,
                          item.imgName
                        )
                      "
                      v-show="checkIfOperationExistForModule('edit')"
                    >
                      mdi-pencil
                    </v-icon>
              </template>
            </v-data-table>
            <div class="text-center pt-4 pb-4">

              <Pagination
            :isloading="isLoading"
            :startRecord="startRecord"
            :currentPage="currentPage"
            :lastPage="lastPage"
            :lastRecord="lastRecord"
            :totRecords="totRecords"
            :isCurrentPageClass="isCurrentPageClass"
            :perpage="perpage"
            :getLastPageClass="getLastPageClass"
            :totPage="totPage"
            :getPages="getPages"
            @handlePerPage="handlePerPage"
            @paginate="paginate"
            @last="last"
            @getDataByPage="getDataByPage"
            :showPerPage="showPerPage"
             />
            </div>

            <div></div>
          </div>
      </v-col>
    </v-row>
    <v-row justify="center">
     <v-dialog
      v-model="toggleCompanyUpdateModal"
      persistent
      transition="dialog-bottom-transition"
      max-width="900"
    >
      <template>
          <v-card>
                    <v-card-title>
                      <span class="headline">Update Company</span>
                    </v-card-title>
                    <v-form ref="form" @submit="updateCompanyData" method="put">
                      <v-container class="px-50" fluid>
                        <v-text-field
                          v-model="updateCompany.companyName"
                          label="Company Name"
                          required
                          :rules="nameRules"
                        ></v-text-field>
                        <div style="color: red">
                          {{ upmessage1 }}
                        </div>

                        <v-row>
                          <v-col cols="3">
                            <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>Parent Company</v-list-item-title>
                              <v-list-item-subtitle v-if="update_parent_company_name != '' ">
                                {{update_parent_company_name}}
                                 <v-icon v-if="update_parent_company_name != ''"  @click="clearParentCompany" small class="mr-2" title="Remove parent Company">mdi-close-circle</v-icon>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle v-else>
                                No parent company assigned
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                            <!-- <v-select
                              v-model="updateCompany.parentCompany"
                              :items="items"
                              :menu-props="{ maxHeight: '400' }"
                              label="Parent Company"
                              readonly
                            ></v-select> -->
                          </v-col>
                          <v-col cols="8">
                            <v-autocomplete
                              v-model="updateCompany.parentCompanySearched"
                              :items="items"
                              :loading="isLoading"
                              item-text="text"
                              item-value="value"
                              :search-input.sync="search"
                              label="Assign New Parent Company"
                              placeholder="Enter minimum 2 characters"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="3">
                            <v-checkbox
                              ref="n"
                              :label="`Approved`"
                              v-model="updateCompany.approved"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="3">
                            <v-checkbox
                          ref="n"
                          :label="`Worksite/Voluntary`"
                          v-model="updateCompany.worksite"
                        ></v-checkbox>
                          </v-col>
                          <v-col cols="3">
                            <v-checkbox
                          ref="n"
                          :label="`Credit Union`"
                          v-model="updateCompany.credit"
                        ></v-checkbox>
                          </v-col>
                          <v-col cols="3">
                            <v-checkbox
                            ref="n"
                          :label="`Private Label`"
                          v-model="updateCompany.privateLabel"
                        ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="3">
                            <v-checkbox
                          ref="n"
                          :label="`Insurance Exchange`"
                          v-model="updateCompany.insurance"
                        ></v-checkbox>
                          </v-col>
                          <v-col cols="3">
                            <v-checkbox
                          ref="n"
                          :label="`Military`"
                          v-model="updateCompany.military"
                        ></v-checkbox>
                          </v-col>
                          <v-col cols="3">
                            <v-checkbox
                          ref="n"
                          :label="`Retail Marketer`"
                          v-model="updateCompany.retail"
                        ></v-checkbox>
                          </v-col>
                          <v-col cols="3">

                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-file-input
                          show-size
                          label="Upload New Company Logo"
                          accept="image/*"
                          prepend-icon="mdi-camera"
                          v-model="updateCompany.image"
                          @change="updateImageNew"
                        ></v-file-input>

                        <h6>
                          [Only .jpg and .png files less than 2MB in size
                          can be uploaded]
                        </h6>
                          </v-col>
                          <v-col cols="6" >

                            <img v-if="updateCompany.showImage != ''"



                          height="100"
                          width="150"

                          :src="updateCompany.showImage"
                        style="border:3px solid #ccc;padding:" >
                          </v-col>
                        </v-row>


                        <!-- <v-checkbox
                          :label="`Update Product Image:`"
                          v-model="updateCompany.updateImage"
                          @click="updateImageNew"
                        ></v-checkbox> -->

                        <v-row>
                          <v-col cols="4">
                            <v-text-field
                          v-model="updateCompany.product"
                          color="purple darken-2 mt-10"
                          label="Add New Product Name"
                          outlined
                          dense
                        ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-btn small
                          @click="addProductData(updateCompany.product)"
                          color="blue-grey"
                          class="ma-2 white--text"
                          >Add</v-btn
                        >
                          </v-col>




                        </v-row>


                        <v-col cols="12"  style="">

                           <span style="font-size:14px;font-weight:410"> Associated Products:</span>

                          <v-divider></v-divider>
                          </v-col>
                        <v-row v-if="product_data.length > 0" style="padding-left:24px">
                           <v-col cols="12" >
                            <template>
                                <v-simple-table dense  fixed-header
                                  height="200px">
                                  <template v-slot:default>

                                      <tr>
                                        <th class="text-left" style="width:85%">
                                          Product Name
                                        </th>
                                        <th class="text-left" style="width:15%">
                                          Action
                                        </th>
                                      </tr>

                                    <tbody>
                                      <tr v-for="(c, i) in product_data" v-bind:key="i" >
                                        <td v-if="editProductMode && editProductIndex === i">
                                          <v-text-field
                                            v-model="editProductName.product_name"
                                            label="Enter New Name"
                                          ></v-text-field>
                                        </td>
                                        <td v-else>{{ c.product_name }}</td>
                                        <td>
                                          <v-btn
                                              tile
                                              x-small
                                              class="mr-5"
                                              style="cursor:pointer;margin-right:5px!important;"
                                              color="primary"
                                              v-if="editProductMode && editProductIndex === i"
                                                @click="updateAssociatedProduct(i)"
                                            >
                                              Ok
                                            </v-btn>
                                          <v-icon
                                                medium
                                                color="grey"
                                                class="mr-5"
                                                style="cursor:pointer;"
                                                v-else
                                                @click="setUpdateAssociatedProduct(c,i)"
                                              >
                                              mdi-pencil
                                            </v-icon>
                                            <v-icon @click.prevent="removeProductData(i)"
                                                                            medium
                                                color="grey"
                                              >
                                              mdi-delete
                                            </v-icon>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </template>

                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12">
                            <v-select
                          v-model="updateCompany.state"
                          :items="states"
                          :menu-props="{ maxHeight: '400' }"
                          label="Associated States/Provinces:"
                          multiple
                        ></v-select>
                          </v-col>
                        </v-row>
                        <div v-if="sectorData.length > 0">
                          <ul>
                            <li v-for="(c, i) in sectorData" v-bind:key="i">
                              <span v-for="(el, index) in c" v-bind:key="index">
                                <strong v-if="index == 0">{{ el.name }}</strong>
                                <strong v-else>/ {{ el.name }}</strong>
                              </span>
                              <span
                              style="position: static;cursor: pointer;"
                                v-ripple="{ class: `primary--text` }"
                                @click.prevent="
                                  traverseSectorCombinations(i, true)
                                "
                              >
                                Up</span
                              >
                              <span
                              style="position: static;cursor: pointer;"
                                v-ripple="{ class: `primary--text` }"
                                @click.prevent="
                                  traverseSectorCombinations(i, false)
                                "
                              >
                                Down</span
                              >

                              <span
                              style="position: static;cursor: pointer;"
                                v-ripple="{ class: `primary--text` }"
                                @click.prevent="removeSectorCombination(i)"
                              >
                                Remove</span
                              >
                            </li>
                          </ul>
                        </div>
                        <v-select
                          v-model="selected_sector"
                          v-show="showAddSectorName"
                          color="purple darken-2"
                          label="Sector"
                          :items="sectors"
                          outlined
                          dense
                          @change="findCateg(selected_sector)"
                        ></v-select>
                        <v-select
                          v-if="selected_sector"
                          v-model="selected_category"
                          :items="secCategory"
                          v-show="showAddSectorName"
                          color="purple darken-2"
                          label="Category"
                          outlined
                          dense
                          @change="findSubCateg(selected_category)"
                        ></v-select>
                        <v-select
                          v-if="selected_category"
                          v-model="selected_subcategory"
                          :items="secSubCategory"
                          v-show="showAddSectorName"
                          color="purple darken-2"
                          label="Sub Category"
                          outlined
                          dense
                        ></v-select>
                        <v-btn
                          v-if="selected_sector"
                          @click="showSectorData()"
                          color="blue darken-1"
                          >Add Sector</v-btn
                        >

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          :disabled="editProductMode"
                          @click="updateCompanyData"
                          type="submit"
                        >
                          Update
                        </v-btn>
                        <v-btn
                          class="close_button mx-2"
                          text
                          @click="closeUpdateModal"
                        >
                          Cancel
                        </v-btn>
                      </v-card-actions>
                      </v-container>
                    </v-form>
                  </v-card>
      </template>
     </v-dialog>
    </v-row>
        <!-- v-data-table-ends -->
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Pagination from "./component/Pagination.vue";

export default {
  components: { Index, Buttons, Loading, Pagination },
  data() {
    return {
      companies:Array,
      showList:Boolean,
      comp_load:false,
      editProductMode:false,
      editProductIndex:false,
      editProductName:null,
      previous_selected_sector_combination: [],
      allowed_file: ["image/png", "image/jpg", "image/jpeg", "image/gif"],
      max_upload_size: 2000000,
      maxfilesize: "2 MB",
      show_zero_product_count: "no",
      toggleCompanyUpdateModal: false,
      //////table data starts here////
      selected: [],
      options: {},
      sort: "",
      total: 0,
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      headers: [
        {
          text: "",
          value: "selectCheckbox",
          width: "5%",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Company Name",
          value: "company_name",
          width: "20%",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Parent Company",
          value: "parent_company_name",
          width: "20%",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Worksite/Voluntary",
          value: "action3",
          width: "5%",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Image",
          value: "action2",
          width: "5%",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Primary(Sec)[Temp]",
          value: "actionC",
          width: "25%",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Action",
          value: "actions",
          width: "",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here
      //pagination code
      perpage: 50,
      totRecords: null,
      page: 1,
      isLoading: true,
      fullPage: true,
      ///
      valid: true,
      checkbox: false,
      fullPage: true,
      company: [],
      checkbox1: false,
      checkbox2: true,
      dialog: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      categories: [],
      page: 1,
      query: "",
      states: [],
      parentCompany: [],
      categ: "",
      category: "",
      deleteItems: [],
      checked_companyid: [],
      checkcedstr: "",
      categor_count: [],
      product_data: [],
      new_products:[],
      edited_products:[],
      deleted_products:[],
      productCount: [],
      companyIds: [],
      company_data: [],
      pageNo: 0,
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      allSelected: false,
      companyNames: [],
      addSectorId: [],
      allSectorIds: [],
      sectorData: [],
      addCompany: {
        companyName: null,
        privateLabel: null,
        parentCompany: null,
        approved: null,
        worksite: null,
        credit: null,
        insurance: null,
        military: null,
        retail: null,
        image: null,
        imageName: null,
        imageSize: null,
        imageType: null,
        updateImage: null,
        state: null,
        sector: null,
        sectorIds: null,
        category: null,
        subCategory: null,
      },
      updateCompany: {
        companyId: null,
        companyName: null,
        privateLabel: null,
        parentCompany: null,
        parentCompanySearched: null,
        approved: null,
        worksite: null,
        credit: null,
        insurance: null,
        military: null,
        retail: null,
        image: null,
        showImage: null,
        imgpath: null,
        imageName: null,
        preimageName: null,
        imageSize: null,
        imageType: null,
        updateImage: false,
        state: [],
        sector: [],
        category: null,
        subCategory: null,
        product: "",
        product_data: [],
      },
      sectors: [],
      secCategory: [],
      secSubCategory: [],
      subcatName: [],
      subcategories: [],
      checked: false,
      toggleAddSectorModal: false,
      validateAddSector: false,
      parent_id: 0,
      showCategory: false,
      showSubCategory: false,
      showSubSubCategory: false,
      showAddSectorName: true,
      all_category: [],
      all_sub_category: [],
      all_sub_sub_category: [],
      //update sector form
      toggleSectorUpdateModel: false,
      validUpdateSector: false,
      all_sectors: [],
      product_data: [],
      allNames: [],
      nameRules: [(v) => !!v || "Name is required"],
      selected_sector: "",
      selected_category: "",
      selected_subcategory: "",
      showPerPage: false,
      ////////////
      descriptionLimit: 60,
      add_parent_items: [],
      items: [],
      model: null,
      search: null,
      search_parent_company: null,
      message: null,
      typing: null,
      debounce: null,
      mappingStatus: 1,
    };
  },
  computed: {
    update_parent_company_name: function () {
      let parent_name = "";
      if (this.items.length) {
        this.items.forEach((o) => {
          if (o.value == this.updateCompany.parentCompany) {
            parent_name = o.text;
          }
        });
      }

      return parent_name;
    },
    // items () {
    //     return this.entries.map(entry => {
    //       const Description = entry.Description.length > this.descriptionLimit
    //         ? entry.Description.slice(0, this.descriptionLimit) + '...'
    //         : entry.Description

    //       return Object.assign({}, entry, { Description })
    //     })
    //   },
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    ///////
  },
  methods: {
    handleBackspace(event){
      if(event.key=='Backspace'){
        this.companies = []
      }
    },
    handleClickOutside(event){
      if(!this.$refs.inputField.$el.contains(event.target)){
        this.showList = false;
      }
    },
    setUpdateAssociatedProduct(c,i){
      let temp = {...c}
      this.editProductMode = true
      this.editProductName = temp
      this.editProductIndex = i
    },
    updateAssociatedProduct(i){
      if(this.product_data[i].product_name != this.editProductName.product_name){
        let duplicate  = this.product_data.find((el)=>el.product_name.toLowerCase() == this.editProductName.product_name.trim().toLowerCase())
        if(duplicate === undefined){
          if(this.product_data[i].id != null){
            this.edited_products.push(this.product_data[i])
          }else{
            let new_added_products = this.new_products.filter((el)=>el != this.product_data[i].product_name)
            this.new_products = new_added_products
            this.new_products.push(this.editProductName.product_name)
          }
          this.product_data[i].product_name = this.editProductName.product_name
          this.updateCompany.product_data[i].product_name  = this.editProductName.product_name
        }else{
          alert('Product Name already exists!')
          return false
        }
      }
      this.editProductMode = false
      this.editProductName = null
      this.editProductIndex = null
    },
    //pagination methods
    closeUpdateModal() {
      this.sectorData = [];
      this.editProductMode = false
      this.editProductName = ''
      this.editProductIndex = null
      this.toggleCompanyUpdateModal = false;
    },
    clearParentCompany() {
      this.items = "";
      this.updateCompany.parentCompanySearched = "";
    },

    showproductCount(event) {
      this.getCompany(1, this.query.trim());
    },
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getCompany(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getCompany(this.page);
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getCompany(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getCompany(this.page);
    },
    /////
    resetSearch() {
      this.query = "";

      console.log(this.query);
      this.page = 1;
      this.getCompany(1);
    },
    validate() {
      this.companies=[]
      let res = this.$refs.form.validate();
      return res
    },
    reset() {
      this.dialog = false;
      this.dialog1 = false;
      this.message1 = null;
      this.upmessage1 = null;
      this.sectorData = [];
      this.allSectorIds = [];
      this.allNames = [];
      this.secCategory = [];
      this.secSubCategory = [];
      this.selected_sector = "";
      this.selected_category = "";
      this.selected_subcategory = "";
      this.companies=[];
      this.addCompany.companyName = ""; 
      this.ad
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
      this.previous_selected_sector_combination = [];
    },
    getCompany(page = "", query = "", reset = "") {
      let check = this;
      if (reset == "reset") {
        this.page = 1;

        this.query = "";
      }
      if (page == 1) {
        this.page = 1;
      }
      const path =
        check.$url("COMPANYDATA") +
        "?q=" +
        check.query.toString() +
        "&page=" +
        page +
        "&show_zero_product_count=" +
        this.show_zero_product_count;

      check.isLoading = true;
      console.log(path);
      let searchdata = {};
      searchdata["q"] = check.query.toString();

      check
        .$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .post(path, searchdata)
        .then((res) => {
          check.isLoading = false;

          console.log(res.data.payload);
          this.company = res.data.payload.company[0];
          this.total = this.company.length;
          this.totRecords = res.data.payload.company[4];
          this.pageNo = res.data.payload.company[1];
          this.all_sectors = res.data.payload.company[3];
          if (res.data.payload.company[3].length) {
            res.data.payload.company[3].forEach(function (item) {
              let a = {
                text: item.name,
                value: item.id,
              };
              check.sectors.push(a);
              check.allSectorIds.push(a);
            });
          }
          let company = this.company;
          if (res.data.payload.company[2].length) {
            res.data.payload.company[2].forEach(function (item) {
              let a = {
                text: item[1],
                value: item[0],
              };
              check.states.push(a);
            });
          }
          // if (res.data.payload.company[0].length) {
          //   res.data.payload.company[0].forEach(function (item) {
          //     let a = {
          //       text: item[1],
          //       value: item[0],
          //     };
          //     check.parentCompany.push(a);
          //   });
          // }
          if (Array.isArray(company) && company.length) {
            this.company_data = [];
            let i;
            for (i = 0; i < company.length; i++) {
              let tempobj = {
                company_id: company[i].company_id,
                company_name: company[i].company_name,
                isWorksiteVoluntary: company[i].isWorksiteVoluntary,
                product_count: company[i].parentCompanyID,
                parent_company_name: company[i].parent_company_name,
                product_countPri: company[i].primary_company_count,
                product_countSec: company[i].secondary_company_count,
                temp_count: company[i].temp_company_count,
                img: company[i].img_co_path,
                imgName: company[i].img_co_filename,
              };
              this.company_data.push(tempobj);
              this.companyNames.push(company[i].company_name);
            }
          }

          // company.forEach(function (comp, i) {
          //   check.parentCompany.forEach(function (parentC, j) {
          //     if (comp[9] == parentC.value) {
          //        check.company_data[i].parent_company = parentC.text;
          //     }
          //   });
          // });

          // if (this.company == 0) {
          //   this.company_data = [];
          //   this.companyIds = [];
          // } else {
          //   let temp = this;
          //   temp.companyIds = [];
          //   temp.company.forEach(function (o, i) {
          //     temp.companyIds.push(o.company_id);
          //   });
          //   this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check })
          //     .get(this.$url("COMPANYID"), {
          //       params: { ids: temp.companyIds },
          //     })
          //     .then((res) => {
          //       this.isLoading = false
          //       // console.log(res.data.payload.count);
          //       let total_counts = res.data.payload.count
          //       let i;
          //       for (i = 0; i < total_counts.length; i++) {
          //         temp.company_data[i].product_countPri = total_counts[i][1] != null ? total_counts[i][1] : 0;
          //         temp.company_data[i].product_countSec = total_counts[i][2] != null ? total_counts[i][2] : 0;
          //         temp.company_data[i].temp_count = total_counts[i][3] != null ? total_counts[i][3] : 0 ;
          //         if (
          //           temp.company_data[i].product_countPri != 0 &&
          //           temp.company_data[i].img != ""
          //         ) {
          //           temp.company_data[i].company_name =
          //             temp.company_data[i].company_name + "*";
          //         }
          //       }
          //     });
          //     check.isLoading = false;
          // }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addCompanyData(e) {
      e.preventDefault();
      let check = this;
      if(!check.validate()){
        this.$swal.fire({
          icon: "error",
          title: "Please enter company name",
        });
        return
      }
      let max_upload_size_error = false;
      let unsupported_file_type_error = false;

      if (
        this.addCompany.image !== null &&
        !this.allowed_file.includes(this.addCompany.image.type)
      ) {
        this.$swal.fire({
          icon: "error",
          title: "Invalid File Type",
          text: "Invalid file type.Please upload jpeg and png file only. ",
        });
      } else if (
        this.addCompany.image !== null &&
        parseInt(this.addCompany.image.size) > parseInt(this.max_upload_size)
      ) {
        this.$swal.fire({
          icon: "error",
          title: "Upload file size Error",
          text: "File size exceeded than " + this.maxfilesize + ".",
        });
      } else {
        check.isLoading = true;
        if (
          this.addCompany.companyName != "" &&
          this.addCompany.companyName != null
        ) {
          let sectorIds = [];
          this.sectorData.forEach(function (o, i) {
            o.forEach(function (sec, i) {
              sectorIds.push(sec.id);
            });
          });
          this.addCompany.sectorIds = sectorIds;
          this.addCompany.sector = this.sectorData;
          if (this.addCompany.image != null) {
            (this.addCompany.imageName = this.addCompany.image.name),
              (this.addCompany.imageSize = this.addCompany.image.size),
              (this.addCompany.imageType = this.addCompany.image.type),
              console.log(this.addCompany.image);
          }
          if (this.addCompany.image != null) {
            let formData = new FormData();
            formData.append("file", this.addCompany.image);
            formData.append("fileSize", this.addCompany.image.size);
            console.log(this.addCompany.image);
            let all_file_uploads_url = this.$url("COMPANYIMG");
            this.$fetch({
              requiresAuth: true,
              operation: "add",
              vueScope: check,
            })
              .post(all_file_uploads_url, formData, {
                headers: {
                  // 'Authorization': accestoken,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
                console.log(response.data.payload);
              });
          }

          this.$fetch({ requiresAuth: true, operation: "add", vueScope: check })
            .post(this.$url("COMPANY"), this.addCompany)
            .then((result) => {
              check.isLoading = false;
              // console.log(result.data.payload[0]);
              this.message1 = result.data.payload[0].companyID;
              this.message2 = result.data.payload[0].companyName;
              // console.log(result.data.payload[0].companyID);
              if (this.message2 == null) {
                this.message1 = result.data.payload[0].companyID;
                this.dialog = true;
              } else {
                this.message1 = null;
                this.dialog = false;
                if (result.data.statusCode == 200 && this.message1 == null) {
                  this.getCompany(this.page, this.query);
                  this.addCompany.companyName = "";
                  this.addCompany.parentCompany = "";
                  this.addCompany.approved = "";
                  this.addCompany.worksite = "";
                  this.addCompany.credit = "";
                  this.addCompany.insurance = "";
                  this.addCompany.military = "";
                  this.addCompany.retail = "";
                  this.addCompany.state = "";
                  this.sectorData = [];
                  this.selected_sector = "";

                  this.previous_selected_sector_combination = [];
                  this.$swal.fire({
                    icon: "success",
                    title: "Record(s) added successfully",
                    text: result.data.payload.message,
                  });
                  check.toggleCompanyUpdateModa = false;
                }
                this.reset();
              }
              // console.log(result);
            })
            .catch((error) => {
              console.error(error);
              check.isLoading = false;
              this.$swal.fire({
                icon: "error",
                title: "Error",
                text: "Something went wrong!",
              });
            });
          if (this.addCompany.image != null) {
            let formData = new FormData();
            formData.append("file", this.addCompany.image);
            formData.append("fileSize", this.addCompany.image.size);
            console.log(this.addCompany.image);
            let all_file_uploads_url = this.$url("COMPANYIMG");
            this.$fetch({
              requiresAuth: true,
              operation: "add",
              vueScope: check,
            })
              .post(all_file_uploads_url, formData, {
                headers: {
                  // 'Authorization': accestoken,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
                console.log(response.data.payload);
              });
          }
        }
      }
    },
    updateImageNew() {
      if (this.updateCompany.image != null) {
        console.log(this.updateCompany.image);
        this.updateCompany.imageName = this.updateCompany.image.name;
        this.updateCompany.imageSize = this.updateCompany.image.size;
        this.updateCompany.imageType = this.updateCompany.image.type;
      }
    },
    addToUpdate(e, atchid, img, imgName) {
      let view = this;
      view.isLoading = true;
      view.updateCompany.showImage = "";
      view.sectorData = [];
      view.updateCompany.product_data = [];
      view.updateCompany.parentCompany = "";

      view.toggleCompanyUpdateModal = true;
      if ((img != null, imgName != null)) {
        let imagePath = img + imgName;
        view.updateCompany.imgpath = img;
        view.updateCompany.preimageName = imgName;

        console.log(view.updateCompany.updateImage);
        this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
          .get(this.$url("COMPANYIMG"), {
            params: { ids: imagePath, company_id: atchid },
          })
          .then((res) => {
            view.isLoading = false;
            console.log(res.data.payload.image);
            view.updateCompany.showImage = res.data.payload.image;
          });
      }
      view.company.forEach(function (o, i) {
        if (o.company_id == atchid) {
          view.updateCompany.state = [];
          view.updateCompany.companyName = o.company_name;
          view.updateCompany.companyId = o.company_id;
          view.updateCompany.approved = o.isApprovedCo;
          view.updateCompany.worksite = o.isWorksiteVoluntary;
          view.updateCompany.credit = o.isCreditUnion;
          view.updateCompany.insurance = o.isInsuranceExchange;
          view.updateCompany.military = o.isMilitaryCo;
          view.updateCompany.retail = o.isRetailMarketer;
          view.updateCompany.privateLabel = o.isPrivateLabel
          if (o.co_states != "") {
            let new_states_arr = o.co_states.split(", ");
            console.log(new_states_arr);
            if (new_states_arr.length) {
              new_states_arr.forEach((s) => {
                view.updateCompany.state.push(parseInt(s));
              });
            }
          }

          if (o.comboIDs != 0) {
            view
              .$fetch({ requiresAuth: true, operation: "add", vueScope: view })
              .post(view.$url("COMPANYSEC"), { secids: o.comboIDs })
              .then((res) => {
                view.isLoading = false;
                view.sectorData = [];
                view.allNames = res.data.payload[0];
                view.sectorData = view.allNames;
              });
          }
          view.product_data = [];
          view.updateCompany.product_data = [];
          view.isLoading = true;
          view
            .$fetch({ requiresAuth: true, operation: "view", vueScope: view })
            .get(
              view.$url("COMPANYPRODUCTS") +
                "?id=" +
                atchid +
                "&parent_id=" +
                o.parentCompanyID
            )
            .then((res) => {
              view.isLoading = false;
              console.log(res.data.payload,"ssss");
              if (res.data.statusCode == 200) {
                if (
                  res.data.payload.parent_data !== undefined &&
                  res.data.payload.parent_data.length
                ) {
                  view.items = [];
                  console.log({
                    value: res.data.payload.parent_data[0][0],
                    text: res.data.payload.parent_data[0][1],
                  });
                  let a = {
                    value: res.data.payload.parent_data[0][0],
                    text: res.data.payload.parent_data[0][1],
                  };
                  view.items.push(a);
                  view.updateCompany.parentCompany = o.parentCompanyID;
                }
                if (res.data.payload.products.length) {
                  // view.mappingStatus = res.data.payload.company_mapping_flag
                  res.data.payload.products.forEach((p) => {
                    if (p[2] != "" && p[2] != null) {
                      view.product_data.push({
                        id:p[0],
                        companyID:p[1],
                        product_name:p[2],
                      });
                    }
                  });
                  // console.log(view.mappingStatus,"ssss.");
                  view.updateCompany.product_data = view.product_data;
                  this.previous_selected_sector_combination = [];
                }
                // view.updateCompany.product_data = view.product_data;
              }
            });
        }
      });
    },
    updateCompanyData(e) {
      e.preventDefault();
      let check = this;
      if(!check.validate()){
        this.$swal.fire({
          icon: "error",
          title: "Please enter company name",
        });
        return
      }
      check.isLoading = true;
      console.log(this.updateCompany.image);
      let image_exceed_size = false;
      let unsupported_file_error = false;
      if (this.updateCompany.image !== null) {
        if (
          parseInt(this.updateCompany.image.size) >
          parseInt(this.max_upload_size)
        ) {
          check.isLoading = false;
          image_exceed_size = true;
        }

        //alert("image ")
      }
      if (
        this.updateCompany.image !== null &&
        !this.allowed_file.includes(this.updateCompany.image.type)
      ) {
        unsupported_file_error = true;
      }
      if (image_exceed_size) {
        check.isLoading = false;
        this.$swal.fire({
          icon: "error",
          title: "Upload file size Error",
          text: "File size exceeded than " + this.maxfilesize + ".",
        });
      } else if (unsupported_file_error) {
        check.isLoading = false;
        this.$swal.fire({
          icon: "error",
          title: "Invalid File Type",
          text: "Invalid file type.Please upload jpeg and png file only.",
        });
      } else {
        if (
          this.updateCompany.showImage == null &&
          this.updateCompany.image != null
        ) {
          console.log(this.updateCompany.image);
          this.updateCompany.imageName = this.updateCompany.image.name;
          console.log(this.updateCompany.imageName);
          this.updateCompany.imageSize = this.updateCompany.image.size;
          this.updateCompany.imageType = this.updateCompany.image.type;
        }
        if (
          this.updateCompany.companyName != "" &&
          this.updateCompany.companyName != null
        ) {
          this.updateCompany.sector = this.sectorData;

          if (this.updateCompany.showImage != null) {
            if (this.updateCompany.image != null) {
              let formData = new FormData();
              formData.append("file", this.updateCompany.image);
              formData.append("fileSize", this.updateCompany.image.size);
              console.log(this.updateCompany.image);
              let file_update_url = this.$url("COMPANYIMG");
              this.$fetch({
                requiresAuth: true,
                operation: "add",
                vueScope: check,
              })
                .post(file_update_url, formData, {
                  headers: {
                    // 'Authorization': accestoken,
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((response) => {
                  console.log(response.data.payload);
                });
            }
          } else if (this.updateCompany.showImage == null) {
            if (this.updateCompany.image != null) {
              let formData = new FormData();
              formData.append("file", this.updateCompany.image);
              formData.append("fileSize", this.updateCompany.image.size);
              console.log(this.updateCompany.image);
              let file_update_url = this.$url("COMPANYIMG");
              this.$fetch({
                requiresAuth: true,
                operation: "add",
                vueScope: check,
              })
                .post(file_update_url, formData, {
                  headers: {
                    // 'Authorization': accestoken,
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((response) => {
                  console.log(response.data.payload);
                });
            }
          }
          this.updateCompany.new_products = this.new_products
          this.updateCompany.deleted_products = this.deleted_products
          this.updateCompany.edited_products = this.edited_products
          this.$fetch({
            requiresAuth: true,
            operation: "edit",
            vueScope: check,
          })
            .put(this.$url("COMPANY"), this.updateCompany)
            .then((result) => {
              check.isLoading = false;
              // console.log(result.data.payload.companyID);
              this.upmessage1 = result.data.payload.companyID;
              this.upmessage2 = result.data.payload.companyName;
              // console.log(result.data.payload.companyID);
              if (this.upmessage2 == null) {
                this.upmessage1 = result.data.payload.companyID;
                this.dialog1 = true;
              } else {
                this.upmessage1 = null;
                this.dialog1 = false;
                let deleteImage = [];
                if (this.updateCompany.image != null) { 9728341603
                  let deleteImagepath = "";
                  deleteImagepath =
                    this.updateCompany.imgpath +
                    this.updateCompany.preimageName;
                  deleteImage.push(deleteImagepath);
                  let file_update_url = this.$url("COMPANYIMG");
                  console.log(deleteImagepath);
                  this.$fetch({
                    requiresAuth: true,
                    operation: "delete",
                    vueScope: check,
                  })
                    .delete(file_update_url, {
                      params: { deleteImage: deleteImage },
                    })
                    .then((response) => {
                      console.log(response.data.payload);
                    });
                }
                if (this.updateCompany.showImage != null) {
                  if (this.updateCompany.image != null) {
                    let formData = new FormData();
                    formData.append("file", this.updateCompany.image);
                    formData.append("fileSize", this.updateCompany.image.size);
                    console.log(this.updateCompany.image);
                    let file_update_url = this.$url("COMPANYIMG");
                    this.$fetch({
                      requiresAuth: true,
                      operation: "add",
                      vueScope: check,
                    })
                      .post(file_update_url, formData, {
                        headers: {
                          // 'Authorization': accestoken,
                          "Content-Type": "multipart/form-data",
                        },
                      })
                      .then((response) => {
                        console.log(response.data.payload);
                      });
                  }
                } else if (this.updateCompany.showImage == null) {
                  if (this.updateCompany.image != null) {
                    let formData = new FormData();
                    formData.append("file", this.updateCompany.image);
                    formData.append("fileSize", this.updateCompany.image.size);
                    console.log(this.updateCompany.image);
                    let file_update_url = this.$url("COMPANYIMG");
                    this.$fetch({
                      requiresAuth: true,
                      operation: "add",
                      vueScope: check,
                    })
                      .post(file_update_url, formData, {
                        headers: {
                          // 'Authorization': accestoken,
                          "Content-Type": "multipart/form-data",
                        },
                      })
                      .then((response) => {
                        console.log(response.data.payload);
                      });
                  }
                }
                if (result.data.statusCode == 200) {
                  check.updateCompany.new_products = []
                  check.updateCompany.deleted_products = []
                  check.updateCompany.edited_products = []
                  check.new_products = []
                  check.deleted_products = []
                  check.edited_products = []
                  check.updateCompany.imageName = null
                  check.updateCompany.imageSize = null
                  check.updateCompany.imageType = null;
                  this.getCompany(check.page, check.query);
                  this.$swal.fire({
                    icon: "success",
                    title: "Record(s) updated successfully",
                    text: result.data.payload.message,
                  });
                  check.toggleCompanyUpdateModal = false;
                }
                this.reset();
              }
              console.log(result);
            })
            .catch((error) => {
              console.error(error);
              check.isLoading = false;
              this.$swal.fire({
                icon: "error",
                title: "Error",
                text: "Something went wrong!",
              });
            });
        }
      }
    },
    addToDelete(e, atchid) {
      this.checkedthis = false;
      //e.target.checked
      console.log(e.target);
      if (e.target.checked) {
        console.log(atchid);
        if (this.checked_companyid.indexOf(atchid) == -1) {
          console.log("pushed" + atchid);
          this.checked_companyid.push(atchid);
        }
      } else {
        this.checked_companyid.splice(
          this.checked_companyid.indexOf(atchid),
          1
        );
      }
      let checkcedstr = this.checked_companyid.join(",");
      this.checkcedstr = checkcedstr;
      console.log(this.checkcedstr);
    },
    deleteCompany(e) {
      e.preventDefault;
      let check = this;
      check.isLoading = true;
      let deleteIds = this.deleteItems;
      let imgPath = "";
      let imagesp = [];
      console.log(deleteIds);
      this.company.forEach(function (o, i) {
        deleteIds.forEach(function (deleteId, i) {
          if (o[0] == deleteId && o[10] != null) {
            imgPath = o[8] + o[10];
            imagesp.push(imgPath);
          }
        });
      });
      console.log(imagesp);
      if (Array.isArray(imagesp) && imagesp.length) {
        let file_update_url = this.$url("COMPANYIMG");
        console.log(imagesp[0]);
        let imgaArray = [];
        imagesp.forEach(function (imgId, i) {
          if (imgId != 0) {
            imgaArray.push(imgId);
          }
        });
        this.$fetch({
          requiresAuth: true,
          operation: "delete",
          vueScope: check,
        })
          .delete(file_update_url, {
            params: { deleteImage: imgaArray },
          })
          .then((response) => {
            console.log(response.data.payload);
          });
      }

      console.log(this.deleteItems);
      this.$fetch({ requiresAuth: true, operation: "delete", vueScope: check })
        .delete(this.$url("COMPANY"), {
          params: { id: this.deleteItems },
        })
        .then((response) => {
          check.isLoading = false;
          if (response.data.statusCode == 200) {
            this.getCompany(this.page, this.query);
            this.$swal.fire({
              icon: "success",
              title: "Selected record(s) has been deleted",
              text: response.data.payload.message,
            });
          }
          this.deleteItems = [];
        });
    },
    selectAll: function () {
      //this.allSelected = true
      let delcompany = this.company;
      if (this.allSelected == true) {
        if (Array.isArray(delcompany) && delcompany.length) {
          this.deleteItems = [];
          let i;
          delcompany.forEach((elem) => {
            if (
              elem.primary_company_count == 0 &&
              elem.secondary_company_count == 0 &&
              elem.temp_company_count == 0
            )
              this.deleteItems.push(elem.company_id);
          });
          // for (i = 0; i < delcompany.length; i++) {
          //   console.log(i)

          //   if (this.delcompany[i].primary_company_count == 0 && this.delcompany[i].secondary_company_count == 0 && this.delcompany[i].temp_company_count == 0) {
          //     console.log("sdfksdkfj")
          //     this.deleteItems.push(delcompany[i]["company_id"]);
          //   }
          // }
        }
      } else if (this.allSelected == false) {
        this.deleteItems = [];
      }
      //console.log(this.deleteItems)
    },
    select: function () {
      this.allSelected = false;
    },
    findSubCateg(atchid) {
      let view = this;
      view.subcategories = [];

      this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        .get(view.$url("COMPANY"), { params: { id: atchid } })
        .then((res) => {
          console.log(res.data.payload.data[4]);
          if (res.data.payload.data[4].length) {
            res.data.payload.data[4].forEach(function (item) {
              item.forEach(function (o) {
                let a = {
                  text: o[1],
                  value: o[0],
                };
                view.subcategories.push(a);
              });
            });
          }
        });
    },
    submit() {
      this.$v.$touch();
    },
    sectorDatas() {
      let check = this;
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .get(view.$url("GETSECTORS"), { params: { id: 0 } })
        .then((res) => {
          if (res.data.payload.length) {
            res.data.payload.forEach(function (item) {
              let a = {
                text: item.text,
                value: item.value,
              };
              view.secCategory.push(a);
              view.allSectorIds.push(a);
            });
          }
        });
    },
    findCateg(atchid) {
      let view = this;
      view.addSectorId.push(atchid);
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        .get(view.$url("GETSECTORS"), { params: { id: atchid } })
        .then((res) => {
          view.secCategory = [];
          // console.log(res.data.payload);
          if (res.data.payload.length) {
            let filtered = view.filter_allowed_sectors(res.data.payload)
            view.secCategory = [];
            view.secCategory = filtered;
          }
        });
    },
    findSubCateg(atchid) {
      let view = this;
      view.addSectorId.push(atchid);
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        .get(view.$url("GETSECTORS"), { params: { id: atchid } })
        .then((res) => {
          view.secSubCategory = [];
          console.log(res.data.payload);
          if (res.data.payload.length) {
            let filtered = view.filter_allowed_sectors(res.data.payload)
            view.secSubCategory = [];
            view.secSubCategory = filtered;
          }
        });
    },
    filter_allowed_sectors: function (response) {
        let allowed_sectors = this.$store.getters['Rawdata/getSectors']
        let filtered = []
        if(response.length){
            response.forEach((o)=>{
                if(allowed_sectors.includes(o.value)){
                    filtered.push(o)
                }
            })
        }
        return filtered
    },
    showSectorData() {
      let selectedCombination = [];
      let check = this;
      selectedCombination.push(this.selected_sector);
      selectedCombination.push(this.selected_category);
      if (this.selected_subcategory != "") {
        selectedCombination.push(this.selected_subcategory);
      }
      console.log("sector data ..... ");
      console.log(selectedCombination);
      let found = false;

      if (this.previous_selected_sector_combination.length > 0) {
        for (
          let i = 0;
          i < this.previous_selected_sector_combination.length;
          i++
        ) {
          for (let j = 0; j < selectedCombination.length; j++) {
            if (
              this.previous_selected_sector_combination[i].includes(
                selectedCombination[j]
              )
            ) {
              found = true;
            } else {
              found = false;
            }
          }
          if (found) {
            break;
          }
          console.log("previous selected sector ..");
          console.log(this.previous_selected_sector_combination[i]);
        }
      }
      // console.log("finding duplicate ..");
      // console.log(found);
      if (found) {
        this.$swal.fire({
          icon: "error",
          title: "Duplicate Sector/Category",
          text: "Sector/Category has been already added.Please add other sector/category combination. ",
        });
        this.previous_selected_sector_combination.splice(i, 1);
        return;
      }
      let combination = [];
      this.$fetch({ requiresAuth: true, operation: "add", vueScope: check })
        .post(this.$url("COMPANYSEC"), { selectedIds: selectedCombination })
        .then((res) => {
          // this.sectorData = [];
          console.log(res.data.payload[0]);
          let allSectors = res.data.payload[0];
          allSectors.forEach(function (o, j) {
            combination.push({ id: o.id, name: o.name });
          });
          this.sectorData.push(combination);
          this.previous_selected_sector_combination.push(selectedCombination);
        });
      this.selected_sector = [];
      this.selected_category = [];
      this.selected_subcategory = [];
      this.secSubCategory = [];
    },
    traverseSectorCombinations: function (i, order) {
      console.log(order);
      var sectorsData = this.sectorData;
      let toObjOrder = "";
      let thisObj = "";
      let thisObjOrder = "";
      let toObj = "";
      (toObjOrder =
        order === true ? parseInt(i) - parseInt(1) : parseInt(i) + parseInt(1)),
        (thisObj = sectorsData[i]),
        (thisObjOrder = i),
        (toObj = sectorsData[toObjOrder]);

      if (
        typeof sectorsData[thisObjOrder] === "undefined" ||
        typeof sectorsData[toObjOrder] === "undefined"
      ) {
        return;
      }
      sectorsData[thisObjOrder] = toObj;
      sectorsData[toObjOrder] = thisObj;
      console.log(sectorsData);
      this.sectorData = [];
      this.sectorData = sectorsData;
      console.log(this.sectorData);
      // this.updateSubmittableSectors(sectorsData);
    },
    removeSectorCombination: function (i) {
      console.log("removing ...");
      console.log(i);
      let view = this,
        sectorsData = view.sectorData;
      if (sectorsData.length == 0) {
        sectorData = [];
        view.updateSubmittableSectors(sectorsData);
        this.previous_selected_sector_combination.splice(0, 1);
      } else {
        if (i > -1) {
          sectorsData.splice(i, 1);
          this.previous_selected_sector_combination.splice(i, 1);
          //console.log(i);
        }
        view.updateSubmittableSectors(sectorsData);
      }
    },
    removeProductData: function (i) {
      let view = this,
        product_data = view.product_data;
      if (product_data.length == 0) {
        product_data = [];
        // view.updateSubmittableSectors(sectorsData);
      } else {
        if (i > -1) {
          if(product_data[i].id != null){
            //old product
            this.deleted_products.push(product_data[i].id)
            let new_edited_products = this.edited_products.filter((el)=>el.id != product_data[i].id)
            this.edited_products = new_edited_products
            product_data.splice(i, 1);
          }else{
            //new product
            let new_added_products = this.new_products.filter((el)=>el != product_data[i].product_name)
            this.new_products = new_added_products
            product_data.splice(i, 1);
          }
          //console.log(i);
        }
        //view.updateSubmittableSectors(sectorsData);
      }
    },
    updateSubmittableSectors: function (payload) {
      let view = this;
      if (Array.isArray(payload) && payload.length) {
        view.sectorData;
        let sectorsSubmittablePayload = [];
        payload.forEach((item) => {
          let newCombination = [];
          item.forEach((o) => {
            if (o.id) {
              newCombination.push(o.id);
            }
          });
          sectorsSubmittablePayload.push(newCombination);
        });
        view.sectorData;
      } else {
        view.sectorData;
        view.sectorData;
      }
    },
    imageUpdate(img) {
      let view = this;
      console.log(img);
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        .get(this.$url("COMPANYIMG"), {
          params: { ids: img.name },
        })
        .then((res) => {
          // console.log(res);
        });
    },
    addProductData(product) {
      console.log(product);
      if (product != "" && product != null) {
        this.product_data.push({id:null,companyID:this.updateCompany.companyId,product_name:product});
        this.new_products.push(product);
        this.updateCompany.product = "";
      }
    },
    searchCompany: function () {
      this.page = 1;

      this.getCompany(this.page, this.query.trim());

      console.log(this.query);
    },
  },
  watch: {
    "$store.state.Rawdata.current_active_module": function (o) {
      if (o) {
        this.getCompany(1);
      }
    },
    "updateCompany.parentCompany": function (o) {
      if (o != null) {
        //this.search = null
        //this.updateCompany.parentCompanySearched = null
      }
    },
    "updateCompany.parentCompanySearched": function (o) {
      if (o != null) {
        this.updateCompany.parentCompany = o;
      }
    },
    search(val) {
      if (val === null) return;
      if (this.isLoading) return;
      let view = this;
      view.items = [];
      if (val.length >= 2 && !view.company_working) {
        view.items = [];
        view.company_working = true;
        view.isLoading = true;
        view
          .$fetch({
            requiresAuth: true,
            operation: "add",
            vueScope: view,
          })
          .post(view.$url("BASE_TEMP_PRODUCT") + "data/company", {
            get_company: val,
          })
          .then(function (res) {
            view.isLoading = false;
            let data = res.data;
            if (data.length) {
              data.forEach(function (item) {
                let a = {
                  value: item.companyID,
                  text: item.companyName,
                };
                view.items.push(a);
              });
            }
            view.company_working = false;
          })
          .catch(function (e) {
            view.isLoading = false;
            console.log(e);
            view.company_working = false;
          });
      }
    },
    "addCompany.companyName"(message) {
      if (message === null) return;
      // if (this.isLoading) return;
      // if (this.comp_load) return;
      let view = this;
      view.companies = [];
      if (message.length >= 2) {
        clearTimeout(view.debounce);
        this.showList = true;
        view.comp_load = true;
        view.debounce = setTimeout(() => {
          let comp_url = view.$url("BASE_TEMP_PRODUCT") + "company_search";
          // let comp_url = "http://0.0.0.0:5054/company_search";
          let data = {
            get_company: message,
          };
          view
            .$fetch({ requiresAuth: true, operation: "add", vueScope: view })
            .post(comp_url, data)
            .then((res) => {
              if (res.data.length) {
                view.companies = res.data;

              } else {
                view.companies = [];
                view.companies = [{companyID:0,companyName:'No matching results'}]
              }
              view.comp_load = false;

            })
            .catch((error) => {
              console.error(error);
            });
        }, 600);

      }
    },
search_parent_company(val) {
      if (val === null) return;
      let view = this;
      if (val.length >= 2) {
        view.add_parent_items = [];
        view.isLoading = true;
        this.message = null
        this.typing = 'You are typing'
        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
            this.typing = null
            this.message = val
            view
          .$fetch({
            requiresAuth: true,
            operation: "add",
            vueScope: view,
          })
          .post(view.$url("BASE_TEMP_PRODUCT") + "data/company", {
            get_company: this.message,
          })
          .then(function (res) {
            view.isLoading = false;
            let data = res.data;
            if (data.length) {
              data.forEach(function (item) {
                let a = {
                  value: item.companyID,
                  text: item.companyName,
                };
                view.add_parent_items.push(a);
              });
            }
          })
          .catch(function (e) {
            view.isLoading = false;
            console.log(e);
          });
        }, 600)
      }else{
        view.add_parent_items = [];
      }
    },
    page: function (ob) {
      this.allSelected = false;
      this.deleteItems = [];
      //this.getCompany(ob, this.query);
    },
  },
  mounted(){
    window.addEventListener('click', this.handleClickOutside);
  }
};
</script>

<style>
#comp_list{
  list-style: none;
  margin: 0;
  margin-top: -18px;
  padding: 0 0 0 0;
  position: absolute;
  z-index: 1;
  width: 318px;
  text-align: left;
  max-height: 239px;
  overflow-y: scroll;
}
#comp_list li{
  background: #E6E6E6;
  border-bottom: 1px solid #BFBFBF;
  padding: 1px;
  cursor: pointer;
  z-index: 1;
}
</style>